import * as Icons from '@ant-design/icons'

import { Dashboard } from './pages/dashboard'
import { CountryList, CountryCreate, CountryEdit, CountryShow } from './pages/country'
import { PlannerList, PlannerCreate, PlannerEdit, PlannerShow } from './pages/events'
import { FansaleList, FansaleCreate, FansaleEdit, FansaleShow } from './pages/fansale'
import { StatusList, StatusCreate, StatusEdit, StatusShow } from './pages/status'
import { AccountList, AccountCreate, AccountEdit, AccountShow } from './pages/account'
import { ConnectionsList /* PhonesCreate, PhonesEdit PhonesShow */ } from './pages/connections'
import { ControlList, ControlCreate, ControlEdit, ControlShow } from './pages/control'
import { GsheetShow } from './pages/gsheet'
import { SalesList } from './pages/sales/list'

const {
  SecurityScanTwoTone,
  DashboardTwoTone,
  SmileTwoTone,
  PhoneTwoTone,
  StarTwoTone,
  ControlTwoTone
} = Icons
// import { workInProgress } from './pages/workInProgress'

const eventim = {
  parentName: 'eventim',
  icon: <SecurityScanTwoTone />,
  list: PlannerList,
  create: PlannerCreate,
  edit: PlannerEdit,
  show: PlannerShow,
  canDelete: true
}

const fansale = {
  parentName: 'fansale',
  icon: <SmileTwoTone />,
  list: FansaleList,
  create: FansaleCreate,
  edit: FansaleEdit,
  show: FansaleShow,
  canDelete: true
}

const account = {
  parentName: 'account',
  icon: <SecurityScanTwoTone />,
  list: AccountList,
  create: AccountCreate,
  edit: AccountEdit,
  show: AccountShow,
  canDelete: true
}

const fansaleEvent = {
  ...eventim,
  parentName: 'fansaleEvent'
}

const ticketmaster = {
  ...eventim,
  parentName: 'ticketmaster'
}

const vivaticket = {
  ...eventim,
  parentName: 'vivaticket'
}

const vivaresale = {
  ...eventim,
  parentName: 'vivaresale'
}

const country = {
  parentName: 'country',
  icon: <StarTwoTone />,
  list: CountryList,
  create: CountryCreate,
  edit: CountryEdit,
  show: CountryShow,
  canDelete: true
}

const sale = {
  parentName: 'sale',
  icon: <SecurityScanTwoTone />,
  list: SalesList
}

export default [
  {
    name: 'dashboard',
    list: Dashboard,
    icon: <StarTwoTone />,
    options: {
      label: 'Dashboard',
      route: 'dashboard'
    }
  },
  {
    name: 'country',
    icon: <StarTwoTone />,
    options: {
      label: 'Country'
    }
  },
  {
    name: 'country/it',
    ...country,
    options: {
      label: 'Italia',
      route: 'it'
    }
  },
  {
    name: 'eventim',
    icon: <SecurityScanTwoTone />,
    options: {
      label: 'Eventim'
    }
  },
  {
    name: 'fansale',
    icon: <SmileTwoTone />,
    options: {
      label: 'Fansale'
    }
  },
  {
    name: 'fansaleEvent',
    icon: <SecurityScanTwoTone />,
    options: {
      label: 'Fansale Event'
    }
  },
  {
    name: 'ticketmaster',
    icon: <SecurityScanTwoTone />,
    options: {
      label: 'Ticketmaster'
    }
  },
  {
    name: 'it-eventim',
    ...eventim,
    options: {
      label: '🇮🇹 ticketone.it',
      route: 'it-eventim'
    }
  },
  {
    name: 'de-eventim',
    ...eventim,
    options: {
      label: '🇩🇪 eventim.de',
      route: 'de-eventim'
    }
  },
  {
    name: 'es-eventim',
    ...eventim,
    options: {
      label: '🇪🇸 entradas.com',
      route: 'es-eventim'
    }
  },
  {
    name: 'uk-eventim',
    ...eventim,
    options: {
      label: '🇬🇧 eventim.co.uk',
      route: 'uk-eventim'
    }
  },
  {
    name: 'ch-eventim',
    ...eventim,
    options: {
      label: '🇨🇭 ticketcorner.ch',
      route: 'ch-eventim'
    }
  },
  {
    name: 'it-fansale',
    ...fansale,
    options: {
      label: '🇮🇹 fansale.it',
      route: 'it-fansale'
    }
  },
  {
    name: 'it-fansaleEvent',
    ...fansaleEvent,
    options: {
      label: '🇮🇹 Fansale Event',
      route: 'it-fansaleEvent'
    }
  },
  {
    name: 'de-fansale',
    ...fansale,
    options: {
      label: '🇩🇪 fansale.de',
      route: 'de-fansale'
    }
  },
  {
    name: 'es-fansale',
    ...fansale,
    options: {
      label: '🇪🇸 fansale.es',
      route: 'es-fansale'
    }
  },
  {
    name: 'uk-fansale',
    ...fansale,
    options: {
      label: '🇬🇧 fansale.co.uk',
      route: 'uk-fansale'
    }
  },
  {
    name: 'ch-fansale',
    ...fansale,
    options: {
      label: '🇨🇭 fansale.ch',
      route: 'ch-fansale'
    }
  },
  {
    name: 'it-ticketmaster',
    ...ticketmaster,
    options: {
      label: '🇮🇹 ticketmaster.it',
      route: 'it-ticketmaster'
    }
  },
  {
    name: 'de-ticketmaster',
    ...ticketmaster,
    options: {
      label: '🇩🇪 ticketmaster.de',
      route: 'de-ticketmaster'
    }
  },
  {
    name: 'es-ticketmaster',
    ...ticketmaster,
    options: {
      label: '🇪🇸 ticketmaster.es',
      route: 'es-ticketmaster'
    }
  },
  {
    name: 'uk-ticketmaster',
    ...ticketmaster,
    options: {
      label: '🇬🇧 ticketmaster.co.uk',
      route: 'uk-ticketmaster'
    }
  },
  {
    name: 'ch-ticketmaster',
    ...ticketmaster,
    options: {
      label: '🇨🇭 ticketmaster.ch',
      route: 'ch-ticketmaster'
    }
  },
  {
    name: 'it-vivaticket',
    ...vivaticket,
    options: {
      label: '🇮🇹 vivaticket.it',
      route: 'it-vivaticket'
    }
  },
  {
    name: 'it-vivaresale',
    ...vivaresale,
    options: {
      label: '🇮🇹 vivaresale.it',
      route: 'it-vivaresale'
    }
  },
  {
    name: 'gigsberg',
    ...sale,
    options: {
      label: 'Gigsberg',
      route: 'gigsberg'
    }
  },
  {
    name: 'viagogo',
    ...sale,
    options: {
      label: 'Viagogo',
      route: 'viagogo'
    }
  },
  {
    name: 'account',
    icon: <SecurityScanTwoTone />,
    options: {
      label: 'Accounts'
    }
  },
  {
    name: 'account-eventim-it',
    ...account,
    options: {
      label: 'Eventim IT',
      route: 'account-eventim-it'
    }
  },
  {
    name: 'account-ticketmaster-it',
    ...account,
    options: {
      label: 'Ticketmaster IT',
      route: 'account-ticketmaster-it'
    }
  },
  {
    name: 'account-vivaticket-it',
    ...account,
    options: {
      label: 'Vivaticket IT',
      route: 'account-vivaticket-it'
    }
  },
  {
    name: 'status',
    icon: <DashboardTwoTone />,
    list: StatusList,
    create: StatusCreate,
    edit: StatusEdit,
    show: StatusShow,
    canDelete: true,
    options: {
      label: 'Status',
      route: 'status'
    }
  },
  {
    name: 'connections',
    icon: <PhoneTwoTone />,
    list: ConnectionsList,
    canDelete: true,
    options: {
      label: 'Connections',
      route: 'connections'
    }
  },
  {
    name: 'control',
    icon: <ControlTwoTone />,
    list: ControlList,
    canDelete: false,
    create: ControlCreate,
    edit: ControlEdit,
    show: ControlShow,
    options: {
      label: 'Control',
      route: 'control'
    }
  },
  // {
  //   name: 'gsheet',
  //   icon: <StarTwoTone  />,
  //   options: {
  //     label: 'Gsheet'
  //   }
  // },
  {
    name: 'gsheet/shipped',
    parentName: 'gsheet',
    icon: <PhoneTwoTone />,
    // list: PhonesList,
    // create: PhonesCreate,
    // edit: PhonesEdit,
    show: GsheetShow,
    // canDelete: true,
    options: {
      label: 'Shipped',
      route: 'shipped'
    }
  }
]
