import type React from 'react'
import { useApiUrl, useNotification, type IResourceComponentsProps } from '@refinedev/core'
import { CustomSearchBar, filterData } from '../../components/shared/CustomSearchBar'
import EditableCell from '../../components/shared/EditableCell'

import {
  useTable, List,
  UrlField
} from '@refinedev/antd'

import {
  Checkbox,
  Popover,
  Table,
  Tag
} from 'antd'

import { useState } from 'react'
import { fetchWithAuth } from '../../fn'
import './style.css'

const printTable = (updatedTableProps: any, handleCellChange: any, stopLossValues: any, setStopLossValues: any): any => {
  return (
    <Table {...updatedTableProps} className='custom-table'>
      <Table.Column
        title='Evento'
        dataIndex='evento'
        key='evento'
        render={
          (evento: string, record: any) =>
            <div>
              <b>🎫 {evento}</b>
              <br />
              🗺 {record?.luogo}
              <br />
              📍 {record?.stadium}
              <br />
              🗓️ {record?.dataEvento}
              <br />
              #️⃣ {record?.idEventoNumeric}
              <br />
              <UrlField target='_blank' value={record?.url}>🌐 URL ↗️</UrlField>
            </div>
        }

      />
      <Table.Column
        title='Annunci'
        dataIndex='annunci'
        key='annunci'
        render={(annunci: object, record: any) => (
          <Table
            dataSource={Object.values(annunci ?? {})}
            columns={[
              {
                title: 'Annuncio',
                dataIndex: 'idAnnuncio',
                key: 'idAnnuncio',
                width: 150,
                render: (idAnnuncio: number, annuncio: any) => (

                  <div style={{ opacity: Number(annuncio.available) ? 1 : 0.3 }}>

                    <Popover
                      content={
                        <div>
                          Section: <Tag color='blue'>{annuncio?.section}</Tag>
                          <br />
                          Block: <Tag color='blue'>{annuncio?.block}</Tag>
                          <br />
                          Row: <Tag color='blue'>{annuncio?.row}</Tag>
                          <br />
                          Restriction: {annuncio?.restriction && <Tag color='blue'>{annuncio?.restriction}</Tag>}
                          <br />
                          #️⃣ Disponibili: <Tag color='blue'> {annuncio.available}</Tag>
                          <br />
                          #️⃣ ID Annuncio: <Tag> {idAnnuncio}</Tag>
                        </div>
                      }
                      title='Dati Annuncio'
                    >
                      <b>{annuncio.available}</b>
                      <span> x </span>
                      <b>{
                        Array.from(
                          new Set(
                            [annuncio?.section, annuncio?.block, annuncio?.row]
                          )
                        )
                          .join(' ')
                          .replace('Row not specified', '')
                          .replace('Block not specified', '')
                          .replace('Row:', '')
                      }
                      </b>
                      <div>
                        <i>{annuncio?.restriction}</i>
                      </div>
                    </Popover>

                  </div>)
              },
              {
                title: 'Price',
                dataIndex: 'price',
                key: 'price',
                width: 100,
                render:
                 (price: number, annuncio: any) => (
                   <div style={{ textAlign: 'center', opacity: Number(annuncio.available) ? 1 : 0.3 }}>
                     <Tag color='green'>€{Number(price).toFixed(2)}</Tag>
                   </div>)
              },
              {
                title: 'Proceeds',
                dataIndex: 'proceeds',
                key: 'proceeds',
                width: 100,
                render:
                (proceeds: number, annuncio: any) => (
                  <div style={{ textAlign: 'center', opacity: Number(annuncio.available) ? 1 : 0.3 }}>
                    <Tag color='gold'>€{proceeds}</Tag>
                  </div>)
              },
              {
                title: (
                  <Popover
                    content={
                      <pre>
                        Valore minimo di ribasso del prezzo, di default è 1,33x il face value
                      </pre>
                    }
                    title='Legenda'
                  >
                    <span>StopLoss❔</span>
                  </Popover>
                ),
                dataIndex: 'stopLoss',
                key: 'stopLoss',
                width: 110,
                render: (stopLoss: number, annuncio: any) => (
                  <EditableCell
                    value={stopLoss ?? (parseFloat(annuncio.faceValue) * 1.33).toFixed(2)}
                    onChange={(value) => handleCellChange(value, 'stopLoss', record.url, annuncio.idAnnuncio)}
                    style={{ backgroundColor: stopLoss ? 'white' : 'ghostwhite', opacity: Number(annuncio.available) ? 1 : 0.3 }}
                  />
                )
              },
              {
                title: (
                  <Popover
                    content={
                      <pre>
                        Valore massimo di rialzo del prezzo, di default è 15x il face value
                      </pre>
                    }
                    title='Legenda'
                  >
                    <span>StopGain❔</span>
                  </Popover>
                ),
                dataIndex: 'stopGain',
                key: 'stopGain',
                width: 110,
                render: (stopGain: number, annuncio: any) => (
                  <EditableCell
                    value={stopGain ?? (parseFloat(annuncio.faceValue) * 15).toFixed(2)}
                    onChange={(value) => handleCellChange(value, 'stopGain', record.url, annuncio.idAnnuncio)}
                    style={{ backgroundColor: stopGain ? 'white' : 'ghostwhite', opacity: Number(annuncio.available) ? 1 : 0.3 }}
                  />
                )
              },
              {
                title: 'Face Value',
                dataIndex: 'faceValue',
                key: 'faceValue',
                width: 100,
                render:
                (faceValue: number, annuncio: any) => (
                  <div style={{ textAlign: 'center', opacity: Number(annuncio.available) ? 1 : 0.3 }}>
                    <Tag color='cyan'>€{faceValue}</Tag>
                  </div>
                )
              },
              {
                title: (
                  <Popover
                    content={
                      <pre>
                        <p><Checkbox indeterminate /> Valore di default: "è un posto numerato"</p>
                        <p><Checkbox checked /> "è un posto numerato"</p>
                        <p><Checkbox checked={false} /> "non è un posto numerato"</p>
                      </pre>
                    }
                    title='Legenda'
                  >
                    <span>Posto Numerato❔</span>
                  </Popover>
                ),

                dataIndex: 'postoNumerato',
                key: 'postoNumerato',
                width: 110,
                render: (postoNumerato: boolean, annuncio: any) => (
                  <EditableCell
                    value={postoNumerato}
                    inputType='checkbox'
                    onChange={(value) => handleCellChange(value, 'postoNumerato', record.url, annuncio.idAnnuncio)}
                    style={{ opacity: Number(annuncio.available) ? 1 : 0.3 }}
                  />
                )
              },
              {
                title: (
                  <Popover
                    content={
                      <pre>
                        <p><Checkbox checked /> Attivo: il bot è attivo su questo annuncio.</p>
                        <p><Checkbox checked={false} /> Disattivo: il bot non è attivo su questo annuncio.</p>
                      </pre>
                    }
                    title='Legenda'
                  >
                    <span>Attivo❔</span>
                  </Popover>
                ),
                dataIndex: 'enabled',
                key: 'enabled',
                width: 80,
                render: (enabled: boolean, annuncio: any) => (
                  <EditableCell
                    value={window.location.pathname.split('/').pop() === 'viagogo' ? enabled ?? false : enabled ?? true}
                    inputType='checkbox'
                    onChange={(value) => handleCellChange(value, 'enabled', record.url, annuncio.idAnnuncio)}
                    style={{ opacity: Number(annuncio.available) ? 1 : 0.3 }}
                  />
                )
              },
              {
                title: (
                  <Popover
                    content={
                      <pre>
                        <p><Checkbox checked /> Attivo: Dati tutti i competitor per l'evento, se attivo posiziona 1 centesimo sotto al miglior competitor</p>
                        <p><Checkbox checked={false} /> Disattivo: Confronta con i competitor a partire da quelli della stessa categoria</p>
                      </pre>
                    }
                    title='Legenda'
                  >
                    <span>Primo In Assoluto❔</span>
                  </Popover>
                ),
                dataIndex: 'considerAllCompetitors',
                key: 'considerAllCompetitors',
                width: 100,
                render: (considerAllCompetitors: boolean, annuncio: any) => (
                  <EditableCell
                    value={considerAllCompetitors ?? false}
                    inputType='checkbox'
                    onChange={(value) => handleCellChange(value, 'considerAllCompetitors', record.url, annuncio.idAnnuncio)}
                    style={{ opacity: Number(annuncio.available) ? 1 : 0.3 }}
                  />
                )
              },
              {
                title: (
                  <Popover
                    content={
                      <pre>
                        <p><Checkbox checked /> Confronta anche il blocco, quindi biglietti con blocco diverso non vengono considerati.</p>
                        <p><Checkbox checked={false} /> Non confronta il blocco, quindi considera solo la categoria per determinare gli annunci concorrenti. (default)</p>
                      </pre>
                    }
                    title='Legenda'
                  >
                    <span>Confronta Blocco❔</span>
                  </Popover>
                ),
                dataIndex: 'checkBlock',
                key: 'checkBlock',
                width: 100,
                render: (checkBlock: boolean, annuncio: any) => (
                  <EditableCell
                    value={checkBlock ?? false}
                    inputType='checkbox'
                    onChange={(value) => handleCellChange(value, 'checkBlock', record.url, annuncio.idAnnuncio)}
                    style={{ opacity: Number(annuncio.available) ? 1 : 0.3 }}
                  />
                )
              },
              {
                title: (
                  <Popover
                    content={
                      <pre>
                        <p><Checkbox checked /> Confronta anche le restrizioni, esempio "Visibilita Limitata"</p>
                        <p><Checkbox checked={false} /> Non confronta le restrizioni. (default)</p>
                      </pre>
                    }
                    title='Legenda'
                  >
                    <span>Confronta Restrizioni❔</span>
                  </Popover>
                ),
                dataIndex: 'checkRestrictions',
                key: 'checkRestrictions',
                width: 100,
                render: (checkRestrictions: boolean, annuncio: any) => (
                  <EditableCell
                    value={checkRestrictions ?? true}
                    inputType='checkbox'
                    onChange={(value) => handleCellChange(value, 'checkRestrictions', record.url, annuncio.idAnnuncio)}
                    style={{ opacity: Number(annuncio.available) ? 1 : 0.3 }}
                  />
                )
              }
            ]}
            pagination={false}
            rowKey='idAnnuncio'
            scroll={{ x: 'max-content' }}
          />
        )}
      />
    </Table>
  )
}
export const SalesList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, tableQuery } = useTable({
    syncWithLocation: true,

    pagination: {
      mode: 'off'
    }
  })

  const { open } = useNotification()

  const API_URL = useApiUrl()

  const [stopLossValues, setStopLossValues] = useState<Record<number, string>>({})

  const handleCellChange = async (value: string | boolean, field: string, url: string, annuncioId: number): Promise<void> => {
    const route = window.location.pathname.split('/').pop() ?? ''

    try {
      console.log(window.location.pathname.replace('/', ''))
      const data = await fetchWithAuth(`${API_URL}/${route}/update-subtable-cell`, {
        method: 'POST',
        body: { annuncioId, url, field, value } // stopLoss: value ? Number(value) : null }
      })

      if (data?.error) {
        open?.({ message: `Failed to update ${field}!`, description: data.error, type: 'error' })
      } else {
        open?.({ message: `${field} updated successfully!`, type: 'success' })
      }

      await tableQuery.refetch()
    } catch (error) {
      console.error(`Failed to update${field}:`, error)
      open?.({ message: `Failed to update ${field}!`, type: 'error' })
    }
  }

  const [query, setQuery] = useState<any>()
  const updatedTableProps = {
    ...tableProps,
    dataSource: filterData(tableProps.dataSource, query)
  }

  return (
    <List
      title={
        <>
          <h2>{'🤖 ' + window.location.pathname.replace('/', '').toUpperCase()}</h2>
        </>
      }
      headerButtons={({ defaultButtons }) => (
        <>
          <CustomSearchBar
            onFilter={setQuery}
          />
        </>
      )}
    >
      {printTable(updatedTableProps, handleCellChange, stopLossValues, setStopLossValues)}
    </List>
  )
}
