import type React from 'react'
import { Checkbox, Input } from 'antd'

interface EditableCellProps {
  value: number | string | boolean
  onChange: (value: string | boolean) => void
  style?: React.CSSProperties
  inputType?: 'text' | 'checkbox'
}

const EditableCell: React.FC<EditableCellProps> = ({ value, onChange, style, inputType = 'text' }) => {
  let inputElement

  if (inputType === 'text') {
    inputElement = (
      <Input
        style={style}
        defaultValue={value as string}
        onBlur={(e) => { e.target.value !== value && onChange(e.target.value) }}
      />
    )
  } else if (inputType === 'checkbox') {
    inputElement = (
      <Checkbox
        style={style}
        indeterminate={value === undefined}
        checked={value as boolean}
        onChange={(e) => { onChange(e.target.checked) }}
      />
    )
  }

  return (
    <div style={{ textAlign: 'center' }}>
      {inputElement}
    </div>
  )
}

export default EditableCell
