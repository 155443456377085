import React, { useState } from 'react'
import { type BaseRecord, type IResourceComponentsProps, type HttpError } from '@refinedev/core'
import { CustomSearchBar, filterData } from '../../components/shared/CustomSearchBar'

import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  ImageField,
  UrlField,
  TagField,
  RefreshButton,
  CreateButton
} from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  Table, Space, // Icons,
  Tooltip, Popover
} from 'antd'

import {
  compareFn,
  convertSeconds,
  toColor,
  toData,
  toSec,
  stringToData,
  ticketsToColor,
  type itemTicket
} from '../../fn'

const {
  // CheckCircleOutlined,
  // CloseCircleOutlined,
  // MinusCircleOutlined,
  DownCircleOutlined,
  DownCircleFilled,
  UpCircleOutlined,
  UpCircleFilled,
  ShoppingOutlined,
  ShoppingFilled,
  ShoppingCartOutlined,
  NotificationOutlined,
  NotificationFilled,
  // PoweroffOutlined,
  // SearchOutlined,
  NumberOutlined,
  // IdcardOutlined,
  ScissorOutlined
} = Icons

export interface obj {
  platform: string
  id: number
  eventId: string
  eventName: string
  photo: string
  eventDate: string
  artist: string
  location: string
  url: string
  frequency: 30
  lastScan: number
  globalLessThen: string
  modus: any
  modusArr: any
  lastAlert: 1706253300.601
  tickets: Record<string, itemTicket>
  map: true
}

const img = (item: obj): any => {
  return (
    <>
      <div>
        <ImageField
          style={{ maxWidth: '100px' }}
          value={item?.photo?.includes('http') ? item?.photo : `https://teddy.bigmoney.biz/${item?.photo}`}
        />
      </div>
    </>
  )
}

const print = (record: Record<string, obj>, fn: (a: obj, record: Record<string, obj>) => any, once = false, fansaleLoop = ''): any => {
  const result = []
  for (const platform of Object.keys(record ?? {})) {
    if (platform === 'id') continue
    if (platform === 'fansaleArr' && !fansaleLoop) continue
    if (platform === 'fansaleArr' && fansaleLoop) {
      for (const fansaleItem of Object.values(record?.[platform])) {
        result.push(fn(fansaleItem, record))
        if (fansaleLoop === 'once') break
      }
    } else {
      if (record?.[platform]) result.push(fn(record?.[platform], record))
    }

    if (once) break
  }
  return result
}

const artist = (item: obj): any => {
  // eslint-disable-next-line operator-linebreak
  return item ?
    <>
      <div style={{ minWidth: '220px', maxWidth: '300px' }}>

        <h3>
          🎫 {item?.artist}
        </h3>

        <div>
          <b>
            📍 {item?.location}
          </b>
        </div>

        <div>
          🗓️ {item?.eventDate || item?.url}
        </div>

      </div>
    </>
    : null
}

export const imgPlatform = (item: { platform: string }, link = ''): any => {
  const img = (
    <img
      style={{ maxWidth: 22, marginRight: 5 }}
      title={item?.platform}
      src={`https://teddy.bigmoney.biz/img/${item?.platform}.png`}
    />
  )
  const a = (<a href={link}>{img}</a>)
  return link ? a : img
}
const link = (item: obj): any => {
  // eslint-disable-next-line operator-linebreak
  return item ?
    <div>
      {imgPlatform(item)}
      {item?.url ? (<UrlField style={{ maxWidth: 22, marginRight: 5 }} target='_blank' value={item?.url}>🌐</UrlField>) : null}
      {item?.eventId ? (<TagField color='blue' value={item?.eventId} />) : null}

      <span>
        {item?.map
          ? <Tooltip title='Scansione in modalita mappa'><span>🗺 Mappa</span></Tooltip>
          : <Tooltip title='Scansione in modalita lista'><span>📄Lista</span></Tooltip>}
      </span>
      <span style={{ marginLeft: 10 }}>
        <Tooltip title='GlobalLessThen filtra i risultati in questo modo: dato un aggiornameto Old => New, sia con Old che New minore di X viene notificato '>
          {item?.globalLessThen ? <TagField value={'🌍 ' + String(item?.globalLessThen)} color='black' icon={<ScissorOutlined />} style={{ fontWeight: 'bold' }} /> : ''}
        </Tooltip>
      </span>

    </div>
    : null
}

export const tickets = (item: obj, record: Record<string, obj | obj[]>): any => {
  const tickets = Object.values(item?.tickets ?? {})
  return (
    <>
      {
        tickets?.map((sector: any, index: number) => {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          const elem = Object.values(record?.fansaleArr ?? {}).find(e => e?.id?.includes((sector?.title + '' + sector?.price)?.replace(/[^A-Za-z0-9]/g, '-')?.replace(/-+/g, '-')?.toLowerCase()))
          const eventimTickets = item?.platform === 'eventim' ? tickets : null
          const {
            tags,
            tagArea,
            tagNumber,
            tagSingle,
            tagCouple
          } = ticketsToColor(sector, item.globalLessThen, item.modus, eventimTickets)
          return (
            <React.Fragment
              key={index}
            >
              <div>
                {
                  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                  elem ? imgPlatform({ platform: 'fansale' }, '/fansale/' + window.location.pathname.split('/')[2] + '-fansale/show/' + elem.id) : null
                }
                {imgPlatform(item)}
                {tags}
                {tagArea}
                {tagNumber}
                {tagSingle}
                {tagCouple}
              </div>

            </React.Fragment>
          )
        })
      }
      <br />
    </>
  )
}

export const printTable = (tableProps: any, filteredData?: any): any => {
  return (
    (
      <Table
        {...tableProps}
        rowKey='id'
      >
        <Table.Column
          dataIndex='id'
          render={(value: string, record: Record<string, obj>, index: number) => (
            <>
              {print(record, img, true)}
            </>
          )}
          title='🖼 Photo'
        />
        <Table.Column
          dataIndex={['artist']}
          key='artist'
          render={(v: any, record: Record<string, obj>, index: any) => (
            <>
              {print(record, artist, true)}
              {print(record, link, false, 'once')}
            </>
          )}
          title='🎫 Event'
          sorter={{ compare: compareFn('eventDate', stringToData) }}
        />
        <Table.Column
          dataIndex='tickets'
          render={(value: any[], record: Record<string, obj>) => (
            <>
              {print(record, tickets)}
            </>
          )}
          title={
            <Popover
              content={
                <div>
                  <p><TagField value='' color='cyan' icon={<NotificationFilled />} />solo notifica</p>
                  <p><TagField value='' color='cyan' icon={<NotificationOutlined />} />solo notifica disabilitata da filtro qta</p>
                  <p><TagField value='⚫️' color='default' icon={<NotificationOutlined />} /> Disabilitato manualmente</p>
                  <p><TagField value='🌍' color='default' icon={<NotificationOutlined />} /> Disabilitato da GlobalLessThan Globale</p>
                  <p><TagField value='2' color='purple' icon={<DownCircleFilled />} style={{ fontWeight: 'bold', minWidth: '40px' }} /> filtro qta minima attivo</p>
                  <p><TagField value='2' color='purple' icon={<DownCircleOutlined />} style={{ minWidth: '40px' }} />filta qta minima non attivo</p>
                  <p><TagField value='100' color='purple' icon={<UpCircleFilled />} style={{ fontWeight: 'bold', minWidth: '40px' }} /> filtro qta massima attivo</p>
                  <p><TagField value='100' color='purple' icon={<UpCircleOutlined />} style={{ minWidth: '40px' }} />filta qta massima non attivo</p>
                  <p><TagField value='10' color='gold' icon={<ShoppingCartOutlined />} style={{ fontWeight: 'bold' }} />auto carrella fino a 10 biglietti</p>
                  <p><TagField value='10' color='gold' icon={<ShoppingCartOutlined />} style={{ fontWeight: 'bold' }} />auto carrella disabilitato da filtro qta</p>
                  <p><TagField value='20' color='green' icon={<ShoppingFilled />} style={{ fontWeight: 'bold' }} />auto compra fino a 20 biglietti</p>
                  <p><TagField value='20' color='green' icon={<ShoppingOutlined />} style={{ fontWeight: 'bold' }} />auto compra disabilitato da filtro qta</p>
                  <p><TagField value='Parterre A € 287,50' color='red' />Settore esaurito</p>
                  <p><TagField value='Parterre A € 287,50' color='green' />Settore disponibile</p>
                  <p><TagField value='Parterre A € 287,50' color='default' />Settore disabilitato da filtro</p>
                  <p><TagField value='0' color='red' icon={<NumberOutlined />} />Posti non disponibili</p>
                  <p><TagField value='327' color='green' icon={<NumberOutlined />} />Posti disponibili</p>
                  <p><TagField value='🛑' color='red' icon={<NumberOutlined />} />Settore cancellato</p>
                </div>
              }
              title='Legenda'
            >
              <span>🎟 Tickets ❔</span>
            </Popover>
          }
        />
        <Table.Column
          dataIndex='frequency'
          title='⏳ Frequency'
          render={(value: any, record: Record<string, obj>) => (
            <>
              {print(record, (item) => <div>{imgPlatform(item)}⏳ {item.frequency}</div>)}
            </>
          )}
        />
        <Table.Column
          dataIndex='lastScan'
          render={(value: any, record: Record<string, obj>) => (
            <>
              {print(
                record,
                (item) =>
                  <div style={{ color: toColor(item?.lastScan), minWidth: '100px' }}>{imgPlatform(item)}⏱
                    {
                      // eslint-disable-next-line multiline-ternary
                      (

                        <Popover
                          content={
                            <>
                              <div style={{ fontWeight: 'bold' }}>{item.id}</div>
                              <div style={{ color: toColor(item?.lastScan), fontWeight: 'bold' }}>{imgPlatform(item)}⏱{item?.lastScan ? toData(item?.lastScan) : 'Never'}</div>
                            </>
                          }
                          title='Data Ultima Scansione'
                        >
                          <b>
                            {item?.lastScan ? convertSeconds(toSec(item?.lastScan)) : 'Never'}
                          </b>
                        </Popover>

                      )

                    }
                  </div>
                , false,
                'once'
              )}
            </>

          )}
          title='⏱ Last Scan'
          sorter={{ compare: compareFn('lastScan') }}
        />
        <Table.Column
          dataIndex='lastAlert'
          render={(value: number, record: Record<string, obj>) => (
            <>
              {print(
                record,
                (item) =>
                  <div>{imgPlatform(item)}⚠️
                    <Popover
                      content={
                        <>
                          <div style={{ fontWeight: 'bold' }}>{item.id}</div>
                          <div style={{ fontWeight: 'bold' }}>{imgPlatform(item)}⚠️{item.lastAlert ? toData(item.lastAlert) : 'Never'}</div>
                        </>
                      }
                      title='Data Ultimo Alert'
                    >
                      <b>
                        {item.lastAlert ? convertSeconds(toSec(item.lastAlert)) : 'Never'}
                      </b>
                    </Popover>
                  </div>
                , false,
                'fansale'
              )}
            </>

          )}
          title='⚠️ Last Alert'
          sorter={{ compare: compareFn('lastAlert') }}
        />
        {
          // eslint-disable-next-line multiline-ternary
          <Table.Column
            dataIndex='id'
            render={(id, record: BaseRecord) => (
              <>
                <div style={{ paddingBottom: 2, paddingTop: 2 }}>
                  <Space>
                    <img
                      style={{ maxWidth: 22, marginRight: 5 }}
                      title='Global Item'
                      src='https://teddy.bigmoney.biz/favicon.ico'
                    />
                    <ShowButton
                      hideText
                      recordItemId={id}
                      size='middle'
                    />
                    <EditButton
                      hideText
                      recordItemId={id}
                      size='middle'
                    />
                    <DeleteButton
                      hideText
                      recordItemId={id}
                      size='middle'
                    />
                  </Space>
                </div>
                {
                  print(record, (item: obj): any =>
                    <div style={{ paddingBottom: 2, paddingTop: 2 }}>
                      <Space>
                        {imgPlatform(item)}
                        <ShowButton
                          resource={`${item.platform}/it-${item.platform}`}
                          hideText
                          recordItemId={item.id}
                          size='middle'
                        />

                        <EditButton
                          resource={`${item.platform}/it-${item.platform}`}
                          hideText
                          recordItemId={item.id}
                          size='middle'
                        />

                        <DeleteButton
                          resource={`${item.platform}/it-${item.platform}`}
                          hideText
                          recordItemId={item.id}
                          size='middle'
                        />
                      </Space>
                    </div>)
                }
              </>
            )}
            title='🕹 Actions'
          />
        }
      </Table>
    )
  )
}

export const CountryList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<obj, HttpError, { q: string }>({
    syncWithLocation: true,
    pagination: {
      mode: 'off'
    }
  })

  const [query, setQuery] = useState<any>()
  const updatedTableProps = {
    ...tableProps,
    dataSource: filterData(tableProps.dataSource, query)
  }

  return (
    <List
      headerButtons={
        <>
          <CustomSearchBar
            onFilter={setQuery}
          />
          <RefreshButton
            onClick={() => { window.location.reload() }}
            size='large'
          />
          <CreateButton
            size='large'
            type='primary'
          />
        </>
      }
      title={'🤖 ' + window.location.pathname.replace('/', '').toUpperCase()}
    >
      {printTable(updatedTableProps)}
    </List>
  )
}
